import { faFile, faFileImage, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const FileIcon = ({ fileName, iconProps = {} }) => {
  const icon = React.useMemo(() => {
    if (fileName.match('pdf'))
      return faFilePdf
    else if (fileName.match(/jpe?g/) || fileName.match('gif') || fileName.match('png'))
      return faFileImage
    return faFile
  }, [fileName])
  return <FontAwesomeIcon icon={icon} {...iconProps}/>
}

export default FileIcon